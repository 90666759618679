import { injectGlobal } from '@emotion/css'
import { rgba } from 'polished'
import theme from '@/constants/theme'

injectGlobal`
  .ant-pagination {
    display: flex;
    .ant-pagination-item {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid ${rgba(theme.colors.font_1, 0.16)};
      &:hover {
        background: ${theme.colors.bg_2};
      }
    }
    .ant-pagination-jump-next,
    .ant-pagination-jump-prev {
      &:hover {
        .ant-pagination-item-link {
          border-color: ${theme.colors.bg_2};
        }
      }
    }
    .ant-select {
      .ant-select-selector {
        border: 1px solid ${rgba(theme.colors.font_1, 0.16)};
        box-shadow: 0px 2px 4px 0px ${rgba(theme.colors.font, 0.08)};
      }
    }
    .ant-pagination-options-quick-jumper {
      margin-left: 24px;
      input {
        width: 32px;
        padding: 4px;
        border: 1px solid ${rgba(theme.colors.font_1, 0.16)};
        box-shadow: 0px 2px 4px 0px ${rgba(theme.colors.font, 0.08)};
      }
    }
    .ant-pagination-total-text {
      color: ${theme.colors.font_1};
      position: absolute;
      left: 16px;
    }
    .ant-pagination-prev,
    .ant-pagination-next {
      .ant-pagination-item-link {
        border-radius: 4px;
        color: ${theme.colors.font_1};
      }
    }
    .ant-pagination-item-container,
    .ant-pagination-item-link {
      width: 100% !important;
      height: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-pagination-item-ellipsis {
      line-height: 32px;
    }
    .ant-pagination-item-active {
      border-radius: 4px;
      background-color: ${theme.colors.primary} !important;
      box-shadow: 0px 4px 8px 0px ${rgba(theme.colors.primary, 0.24)};
      a {
        color: #fff !important;
      }
    }
  }
`
