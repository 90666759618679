import { injectGlobal } from '@emotion/css'
import { rgba } from 'polished'
import theme from '@/constants/theme'

injectGlobal`
  .ant-slider .ant-slider-track {
    background-color: ${rgba(theme.colors.primary, 0.55)};
  }
  .ant-slider .ant-slider-handle::after {
    box-shadow: 0 0 0 2px #B0A0FF;
  }
`
