const theme = {
  colors: {
    // 背景颜色
    bg: '#F7F7FA', // 一般做大背景色
    bg_1: '#EEEEF6', // 背景上层图层的hover色，eg:右侧面板的hover色
    bg_2: '#F5F5FA', // 白色背景的hover色
    bg_3: '#626999', // 输入框背景色等, 一般需要配合透明度一起使用
    bg_dark: '#3F3F44', // toast, 气泡等等颜色
    // 边框分割线
    line: '#E1E1E5', // 分割线颜色, 一般使用要配合透明度一起使用
    // 图标&文本颜色
    font: '#17171d', // 最常用的文本颜色
    font_1: '#8D8D99', // 次级文本的颜色，次级标题，图标等等
    font_2: '#979797',
    font_red: '#E54A17', // 红色文本
    // 主色调-紫色
    primary: '#7B61FF', // 紫色强调色
    // 强调色
    success: '#00B042', //  成功色
    error: '#FF5219', // 红色，一般是错误按钮
    warning: '#FE9700', // 金黄色，警告使用
    tips: '#38A6FF', // 蓝色，标记使用, 例如：高亮块
    // 辅助色
    green: '#25BC68', // 绿色
    orange: '#ED9B23', // 橘色
    pink: '#E55C91', // 粉色
    brown: '#B87332', // 棕色
    // flow按钮
    btn_white: '#f7f7ff',
  },
}

// 业务配色，一般知识库的图标背景色，应用的图标背景色，等等
export const colors = [
  '#14ABF1',
  '#2CB969',
  '#7B67EE',
  '#F36651',
  '#DCA900',
  '#75779C',
  // 'linear-gradient(314deg, rgba(226, 29, 111, 0.2) 7%, rgba(210, 72, 182, 0.2) 13%, rgba(109, 60, 255, 0.2) 48%, rgba(123, 97, 255, 0.2) 68%, rgba(69, 152, 255, 0.2) 88%)',
]

export default theme
