import { injectGlobal } from '@emotion/css'

injectGlobal`
  .ant-tooltip {
    .ant-tooltip-inner {
      min-height: auto;
      min-width: auto;
      border-radius: 6px;
      opacity: 1;
      background-color: #3F3F44;
      backdrop-filter: blur(16px);
      box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);
      font-size: 12px;
      line-height: 1.5714285714285714;
      /* line-height: 1px; */
    }
    .ant-tooltip-arrow {
      color: #3F3F44;
    }
    .ant-tooltip-arrow:before {
      background-color: #3F3F44;
    }
  }

  .antd-bty-tooltip-light {
    .ant-tooltip-arrow:before {
      background-color: white;
    }
  }

`
