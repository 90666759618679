import { ConfigProvider } from 'antd'
import zhCN from 'antd/locale/zh_CN'
import dayjs from 'dayjs'
import theme from '@/constants/theme'
import 'dayjs/locale/zh-cn'
import './antd/index'

dayjs.locale('zh-cn') // antd全局样式

export function AntdProvider({ children }: { children: React.ReactNode }) {
  return (
    <ConfigProvider
      locale={zhCN}
      button={{
        autoInsertSpace: false,
      }}
      theme={{
        token: {
          wireframe: false,
          colorInfo: theme.colors.primary,
          colorPrimaryActive: theme.colors.primary,
          colorPrimaryBorderHover: theme.colors.primary,
          colorPrimary: theme.colors.primary,
          colorText: theme.colors.font,
          colorPrimaryHover: theme.colors.primary,
          borderRadius: 8,
          colorPrimaryBg: theme.colors.bg_1,
          fontSize: 14,
          colorError: theme.colors.error,
          colorWarning: theme.colors.warning,
        },
      }}
    >
      {children}
    </ConfigProvider>
  )
}
