import { injectGlobal } from '@emotion/css'

injectGlobal`
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.45)!important;
    backdrop-filter: blur(16px);
  }
  .keyu-modal {
    .ant-modal-content {
      padding: 0;
    }
    .ant-modal-footer {
      margin-top: 0;
    }
  }
`
