import { injectGlobal } from '@emotion/css'
import theme from '@/constants/theme'

injectGlobal`
  // dropdown 暗黑主题
  .ant-select-dropdown {
    .rc-virtual-list-holder-inner {
      gap: 4px
    }
  }
  .dropdown-dark {
      .ant-select-item {
        margin-bottom: 4px;
      }
      .ant-select-show-arrow .ant-select-selection-item {
        padding-inline-end: 16px
      }
      .ant-dropdown-menu {
        padding: 4px 8px;
        border-radius: 8px;
        /* margin-top: 16px; */
        background: rgba(21, 9, 36, 0.9);
        border: 1px solid rgba(255, 255, 255, 0.06);
        backdrop-filter: blur(24px);
        box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.4);
        .ant-dropdown-menu-item  {
          color: ${theme.colors.font_1};
          min-height: 32px;
          margin-block: 4px;
        }
        .ant-dropdown-menu-item.ant-dropdown-menu-item-active {
          background: rgba(255, 255, 255, 0.06);
          color:#fff;
        }
        .ant-dropdown-menu-item.ant-dropdown-menu-item-selected {
          color: #fff;
          background: rgba(255, 255, 255, 0.06);
        }
      }
      &.ant-select-dropdown {
        padding: 8px;
        border-radius: 8px;
        /* margin-top: 16px; */
        background: rgba(21, 9, 36, 0.9);
        border: 1px solid rgba(255, 255, 255, 0.06);
        backdrop-filter: blur(24px);
        box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.4);
        .ant-select-item-option-content {
          color: ${theme.colors.font_1};
        }
        .ant-select-item-option-active,.ant-select-item-option-selected {
          .ant-select-item-option-content {
            color: #fff;
          }
          color:#fff;
          background: rgba(255, 255, 255, 0.06);
        }
      }
  }
  // dropdown 明亮主题
  .dropdown-light {
      .ant-dropdown-menu {
        padding: 0px 4px 4px 4px!important;
        border-radius: 8px;
        /* margin-top: 16px; */
        background: #fff;
        border: 1px solid rgba(255, 255, 255, 0.06);
        backdrop-filter: blur(24px);
        box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.12);
        .ant-dropdown-menu-item  {
          color: ${theme.colors.font};
          min-height: 32px;
          margin-block: 4px;
        }
        .ant-dropdown-menu-item.ant-dropdown-menu-item-active {
          background: rgba(98, 105, 153, 0.08);
          /* color:#fff; */
        }
        .ant-dropdown-menu-item.ant-dropdown-menu-item-selected {
          /* color: #fff; */
          background: rgba(255, 255, 255, 0.06);
        }
      }
      &.ant-select-dropdown {
        padding: 8px;
        border-radius: 8px;
        background: #fff;
        border: 1px solid rgba(255, 255, 255, 0.06);
        backdrop-filter: blur(24px);
        box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.4);
        .ant-select-item-option-content {
          color: ${theme.colors.font_1};
        }
        .ant-select-item-option-active {

        }

       
        .ant-select-item-option-selected {
          background: rgba(123, 97, 255, 0.08);
          .ant-select-item-option-content {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
  }
  .ant-dropdown {
    && {
      .ant-dropdown-menu {
        padding: 8px;
        .ant-dropdown-menu-item {
          padding: 0;
          margin-bottom: 4px;
          &.ant-dropdown-menu-item-selected {
            color: #fff;
            background: rgba(123, 97, 255, 0.08);
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`
