import { injectGlobal } from '@emotion/css'
import { rgba } from 'polished'
import theme from '@/constants/theme'

injectGlobal`
  .ant-select-item-option-content {
    line-height: inherit;
  }
  .ant-select-item-option.ant-select-item.ant-select-item-option-selected {
    font-weight: normal!important;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: ${rgba(theme.colors.primary, 0.08)};
  }
 .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${rgba(theme.colors.primary, 0.08)}!important;
  }
  .ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: ${rgba(theme.colors.bg_3, 0.08)};
  }
  .ant-select-item-option-disable {
    color: ${rgba(theme.colors.font_1, 0.6)}
  }
  //.ant-select-item:not(:last-of-type) {
  //  margin-bottom: 4px;
  //}
  .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${rgba('#7F69FF', 0.08)};
  }
  .keyu-dropdown-menu-small {
    .ant-select-item-option-content {
      font-size: 12px;
      line-height: 22px;
    }
  }
`
