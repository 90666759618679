import { injectGlobal } from '@emotion/css'

injectGlobal`
 .ant-form-item-explain {
    margin-top: 6px;
  }
  .ant-form-item-explain-error {
    font-size: 12px;
    margin-left: 6px;
  }

`
