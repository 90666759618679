import { injectGlobal } from '@emotion/css'
import theme from '@/constants/theme'

injectGlobal`
  .ant-btn.ant-btn-primary:not(.ant-btn-dangerous) {
    background-color: ${theme.colors.primary};
  }
  .ant-switch {
    background-color: rgba(98,105,153, 0.32)!important;
  }
  .ant-switch.ant-switch-checked {
  background:  ${theme.colors.primary}!important;
  }
`
